
/* @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Kreon:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Pratawght@400;700&display=swap'); */



body {
    font-family: 'Prata', serif;
    /* background-color: hsl(45, 72%, 52%); */
    /* Uncomment if you want a background color */
}

main {
    /* background-color: #E3E2DF; */
    /* Uncomment if you want a background color */
}